<div class="contenedorPadre">
    <mat-card class="container">
        <mat-card-content>
            <div class="forms-container">
                <div class="closeAlert">
                    <button mat-mini-fab class="buttonPrincipal" (click)="navegar()">
                        <mat-icon>exit_to_app</mat-icon>
                    </button>
                </div>
                <div class="img-signup">
                    <img src="assets/images/undraw_authentication_fsn5.svg" class="img-fluid">
                </div>
                <div class="signin-signup">

                    <div *ngIf="!informacionUsuario">
                        <h2 class="title">Recupera tu usuario</h2>
                        <p class="mt-4">Para recuperar tu usuario ingresa tu numero de identidad</p>
                        <div class="row">

                            <mat-form-field appearance="legacy" class="col-md-12">
                                <mat-label>Numero de identidad</mat-label>
                                <input matInput placeholder="Numero de identidad" [formControl]="usuario"
                                    autocomplete="off" required>
                            </mat-form-field>

                        </div>

                        
                        <div class="alert alert-secondary" role="alert" *ngIf="alerta != ''">
                           {{alerta}}
                        </div>

                        <app-loading [data]="4" *ngIf="(recuperarUsuarioFacade.responseCargando$ | async)"></app-loading>

                        <div class="text-center">
                            <button type="button" mat-raised-button class="buttonPrincipal" (click)="obtenerUsuario()" *ngIf="!(recuperarUsuarioFacade.responseCargando$ | async)"> Confirmar</button>
                        </div>
                    </div>

                    <div *ngIf="!error && informacionUsuario">
                        <mat-list>
                            <mat-list-item>
                                <mat-icon mat-list-icon>account_circle</mat-icon>
                                <div mat-line>Nombre: </div>
                                <div mat-line>{{informacionUsuario?.primerNombre}} {{informacionUsuario?.segundoNombre}} {{informacionUsuario?.primerApellido}} {{informacionUsuario?.segundoApellido}} </div>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                        </mat-list>
                        <mat-list>
                            <mat-list-item>
                                <mat-icon mat-list-icon>badge</mat-icon>
                                <div mat-line>Numero Colegiación</div>
                                <div mat-line> {{informacionUsuario?.noColegiacion}} </div>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                        </mat-list>

                        <div class="text-center mt-2">
                            <button type="button" mat-raised-button class="buttonSecundary" (click)="regresar()"> Regresar</button>
                        </div>


                    </div>


                </div>
            </div>

        </mat-card-content>
    </mat-card>
</div>