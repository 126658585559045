import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import * as sha1 from 'sha1';
import { ToastrServiceLocal } from 'src/app/services/toast/toastr.service';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public formLogin: FormGroup;
  public correo = new FormControl('', Validators.required);
  public hide = true;

  private url: string = "";

  public cambioPass: boolean = false;
  public suscripciones: Subscription = new Subscription();

  constructor(private toastr: ToastrServiceLocal, public authService: AuthService, @Inject(DOCUMENT) document: any) {
    this.formLogin = new FormGroup({
      usuario: new FormControl('', [Validators.required]),
      clave: new FormControl('', [Validators.required])
    });
    this.url = document.location.href;
    console.log(this.url)
  }

  ngOnInit(): void {
  }


  login() {
    if (this.formLogin.invalid) {
      this.formLogin.markAllAsTouched();
      return;
    }
    this.authService.Login({
      clave: sha1(this.formLogin.value.clave),
      usuario: this.formLogin.value.usuario,
      idTipoUsuario: 1
    });
  }

  envioCambioPassword() {
    if (this.correo.invalid) {
      this.toastr.mensajeWarning('Es necesario completar los campos requeridos', '')
    };

    this.authService.solicitudCambioPassSinToken({
      usuario: this.correo.value,
      url: this.url
    });


    this.suscripciones.add(
      this.authService.responseAction$.subscribe((result) => {
        if (result.hasError == false) {
          this.cambioPass = false;
          this.correo.setValue('');
        }
      })
    );
  }


}
